
/*********************/
/* MOBILE & DEFAULTS */
/*********************/
.bg-k {
    background-image: none;
}

.team-cell {
    height:9rem;
    background-color:white;
}

/**********/
/* TABLET */
/**********/
@media (min-width: 768px) {

}

/*****************/
/* SMALL DESKTOP */
/*****************/
@media (min-width: 992px) {
    h1 {
        font-size: 4rem;
    }

    .bg-k {
        background-image: url('https://cdn.krikeyapp.com/krikey.xyz/images/about/kbig.webp');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .team-cell {
        height:5rem;
        background-color:white;
    }
}

/******************/
/* MEDIUM DESKTOP */
/******************/
@media (min-width: 1200px) {
    .team-cell {
        height:6rem;
        background-color:white;
    }
}

/*****************/
/* LARGE DESKTOP */
/*****************/
@media (min-width: 1400px) {
    @media (min-width: 1200px) {
        .team-cell {
            height:6.5rem;
            background-color:white;
        }
    }
}

/******************************************/
/* EXTRA LARGE DESKTOP / HIGH RES MONITOR */
/******************************************/
@media (min-width: 1700px) {

}

/******************************************/
/* EXTRA LARGE DESKTOP / HIGH RES MONITOR */
/******************************************/
@media (min-width: 2000px) {
}