.product-tab.active {
    background-color: #252533 !important;
    color: white !important;
    border: 2px solid #252533 !important;
  }

.product-tab {
    color: #252533 !important;
    border-left: 2px solid #252533 !important;
    border-right: 2px solid #252533 !important;
    border-top: 2px solid #252533 !important;
}