body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none;
}

footer ul {
  list-style-type: none;
}

@font-face {
  font-family: 'ReemKufi';
  src: local('ReemKufi'), url(./fonts/Reem_Kufi/ReemKufi-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-bold';
  src: local('Montserrat'), url(./fonts/Montserrat/static/Montserrat-Bold.ttf) format('truetype');
}

input[type=range]::-webkit-slider-thumb {
  background: #655CE4 !important;
}

.scrollableContainer {
  height: 1000px;
  overflow-y: auto;
}

.accordion-button img, svg {
  padding-right: 0.25rem;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button-remove::after {
  background-image: none;
}

.accordion-button-remove:not(.collapsed)::after {
  background-image: none;
}

.accordion-overwrite {
  border-top: 2px solid #0082D6;
  border-right: 2px solid #0082D6;
}

.accordion-overwrite .accordion-item {
  background-color: transparent;
}

.accordion-overwrite .accordion-button {
  background-color: transparent;
  font-size: 1.6rem;
}

.accordion-overwrite .accordion-header {
  border-bottom: 2px solid #0082D6;
}

.accordion-overwrite .accordion-body {
  background-color: #16173E;
  background-color: rgba(0, 0, 0, 0.4);
}

.border-top-blue-1 {
  border-top: 1px solid #0082D6;
}

.border-top-blue-2 {
  border-top: 2px solid #0082D6;
}

.border-bottom-blue-1 {
  border-bottom: 1px solid #0082D6;
}

.border-bottom-blue-2 {
  border-bottom: 2px solid #0082D6;
}

.btn-size-xl {
  border-radius: 40px;
  font-size: 0.9em;
  width: 130px;
  height: 35px;
}

.btn-size-lg {
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  height: 1.9rem;
}

.btn-size-md {
  border-radius: 15px;
  font-size: 1.2rem;
  width: 12rem;
  height: 3rem;
}

.btn-size-sm {
  border-radius: 8px;
  font-size: 1em;
  width: 7rem;
  height: 2.3rem;
}

.btn-hollow {
  /*background: #1A0833;*/
  background: rgba(0,0,0,0.1);
  border-radius: 40px;
  border: 2px solid #ea37c2;
}

.btn-disabled {
  background: #D2D0F4 !important;
  color: #A0A3BD !important;;
}

.btn-normal {
  background: linear-gradient(89.82deg, #5A50E2 0.12%, #12BABA 99.82%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 0;
}

.btn-normal-outline {
  background: #000019;
  border: 2px solid #655CE4;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.2));
  border-radius: 5px;
}

.btn-orange {
  background: #F26B40;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 0;
}

.btn-blue {
  background: rgb(83,121,232);
  background: linear-gradient(90deg, rgba(83,121,232,1) 50%, rgba(77,181,201,1) 100%);
  border-radius: 10px;
  border: 0;
}

.btn-strong-blue {
  background: #4D44D7;
  border: 0;
  border-radius: 10px;
}

.btn-crypto {
  background: #000000;
  border-radius: 40px;
  border: 2px solid #6ffbd2;
}

.btn-purple {
  background: rgb(23,23,62);
  border: 2px solid #ea37c2;
  border-radius: 40px;
}

.btn-width-wide {
  width: 150px;
}

.btn-credit {
  min-width: 100px;
  height: 35px;
  background: #252533;
  border-radius: 8px;
  border: 0;
  color: #12AFBD;
}

.btn-normal:hover {
  /*    background: rgba(0, 0, 0, 0.8);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);*/
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: #16173E;
}

.bg-space {
  --bs-bg-opacity: 1;
  background-color: #382B77;
}

.bg-purple-gradient {
  background: rgb(33, 22, 73);
  background: linear-gradient(0deg, rgba(33, 22, 73, 1) 0%, rgba(71, 41, 112, 1) 100%);
}

.bg-nft {
  background-image: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-space.png');
  background-size: 480%;
  background-repeat: no-repeat;
  /*background-position: center center;*/
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 50%);
}

.bg-marketplace {
  background-image: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-space-tile.jpg');
  background-size: 100%;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 50%);
}

.rounded-modal {
  border-radius: 16px;
}

.bg-modal {
  background-image: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-space.png');
  background-repeat: no-repeat;
  background-size: 500%;
  background-position: center center;
  border-radius: 30px;
}

.bg-roadmap {
  background-image: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-startfield.webp');
  background-size: 120%;
  background-repeat: no-repeat;
  height: 25rem;
  background-position: center center;
}

.bg-login-modal {
  background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-space.png') no-repeat center 55%;
  border-radius: 16px;
}

.bg-cover-happiness-bar {
  background-size: 100%;
  background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/banner-moon.png') no-repeat center 60%;
  height: 50px;
}

.bg-cover-headphones {
  height: 23rem;
  background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-headphones.jpg');
}

.bg-cover-solar-pups {
  background-color: #150E0E;
}

.bg-cover-stars {
  background-size: 100%;
  background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/banner-stars.png') no-repeat center 60%;
  height: 10px;
}

.bg-haze {
  background: rgba(0.3, 0.3, 0.3, 0.3);
  border: 2px solid white;
  border-radius: 0.5rem;
}

.buttons-solar-pups {
  padding-top: 7.5rem;
}

.buttons-solar-pups img {
  width: 7rem;
}

.btn-solar-pups-opensea {
  width: 10px;
}

.circle-profile {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background-color: #4D44D7;
  border: 0;
  font-size: 20px;
  padding: 0;
}

.circle-profile-mini {
  width: 26px;
  height: 26px;
  border-radius: 30px;
  background-color: #4D44D7;
  border: 0;
  font-size: 13px;
  padding: 0;
}

.color-blue-gradient {
  font-size: 72px;
  background: -webkit-linear-gradient(0deg, rgba(92,133,255,1) 25%, rgba(79,182,205,1) 100%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-component {
  background-color: #16173E;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #0082D6;
  color: white;
}

.form-component:focus {
  background-color: #16173E;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
}

.solarpups-pink {
  color: #DC2FCB;
}

.krikey-green {
  color: #71FDD2;
}

.krikey-pink {
  color: #FE4080;
}

.krikey-yellow {
  color: #FDCD22;
}

.krikey-blue {
  color: #1D99E5;
}

.navbar-toggler-button {
  background-color: #E736C4;
  border-radius: 10px;
}

.navbar-mobile {
  background: rgba(0.75, 0.75, 0.75, 0.75);
  position: absolute;
  right: 0;
  width: 100%;
  justify-content: flex-end !important;
}

.navbar-link {
  font-size: 0.9rem;
}

.navbar-img {
  width: 2.7rem;
}

.nft-card {
  border: 0;
  border-radius: 2rem;
  background-color: #000019;
}

.nft-card .card-body {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.nft-image {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.nft-image {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.video-overlay {
  position:absolute;
  top:0;
  left:0;
  z-index:1;
}

.ribbon-home {
  position: absolute;
  top: -2.9rem;
  left: 0;
}
.ribbon-marketplace {
  width: 8rem;
  position: absolute;
  top: -1.75rem;
  left: 0;
}
.ribbon-marketplace-hidden {
  display: none;
}
.ribbon-modal {
  width: 7rem;
  position: absolute;
  top: 5.7rem;
  left: 3rem;
}
.ribbon-modal-card {
  width: 8rem;
  position: absolute;
  top: -1.7rem;
  left: 0;
}

.ribbon-soldout {
  width: 9rem;
  position: absolute;
  top: -1.5rem;
  left: -1.7rem;
}

.ribbon-nft {
  width: 10rem;
  position: absolute;
  top: 4.3rem;
  left: 2.2rem;
}
.ribbon-panel {
  background: rgba(0.2, 0.2, 0.2, 0.2);
}

.clock-marketplace {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 4px;
  gap: 4px;
  background: linear-gradient(89.88deg, #FF45B5 -32.41%, #8F00FF 162.6%);
  border-radius: 8px;
  color: #FFFFFF;
  text-align: right;
}
.clock-marketplace-image {
  width: 1.5rem;
  top: 0.5rem;
  left: 0.5rem;
  padding: 0.1rem;
  gap: 0.4rem;
  position: absolute;
}
.clock-marketplace-hidden {
  display: none;
}

.loading-progress {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #3C6DFF;
  border-radius: 40px;
}

.loading-progressbar {
  background: linear-gradient(270deg, rgba(94, 246, 255, 0.71) 0%, #5C85FF 88.02%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.59);
  border-radius: 40px;
}

.x {
  -webkit-animation: x 13s linear infinite alternate;
  animation: x 13s linear infinite alternate;
}

.y {
  -webkit-animation: y 1s linear infinite alternate;
  animation: y 7s linear infinite alternate;
}

.form-control:disabled, .form-control[readonly] {
  background-color: transparent !important;
}

@-webkit-keyframes x {
  100% {
    transform: translateX(calc(100vw - 100px));
  }
}
@keyframes x {
  100% {
    transform: translateX(calc(100vw - 100px));
  }
}

.tooltip-bg {
  background: url("https://cdn.solarpups.com/web/assets/img/solar-pups/bg-tooltip.png") no-repeat 50% 50%;
  border-radius: .75em;
}

.popover-krikey {
  max-width: 300px;
  border: none;
  border-radius: unset;
  font-family: inherit;
  background-color: transparent;
}

.popover-arrow::after {
  /*bottom: 0 !important;*/
  border-top-color: rgba(72, 18, 67, 0.65) !important;
}

.font-normal {
  font-weight: 600;
}

.font-bold {
  font-family: 'Montserrat-bold', sans-serif;
  font-weight: 900;
}

.text-orange {
  color: #F26B40;
}

.text-blue {
  color: #655CE4;
}

.bg-black {
  background-color: #191413;
}

.image-banner-title-1 {
  font-size: 3.5em;
  text-shadow: 0px 1px 11px #4BC4EA;
}

.image-banner-title-2 {
  font-size: 1.5em;
  text-shadow: 0px -3px 30px #19A5A5;
}

.home-banner-title-1 {
  font-size: 1.6em;
  /*text-shadow: 0 2px 36px rgba(242, 107, 64, 0.8);*/
  background: linear-gradient(89.82deg, #5F57DB 0.12%, #45DFED 99.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 36px rgba(49, 62, 179, 0.8);
}

.home-banner-title-2 {
  font-size: 1em;
  color: #FFFFFF;
  text-shadow: 0 -3px 30px #19A5A5;
}

.home-banner-title-3 {
  font-size: 1em;
}

.home-banner-input {
  border: 1px solid #FFFFFF;
  filter: drop-shadow(0px 0px 5px #FFFFFF);
  border-radius: 8px;
  background: transparent;
}

.home-banner-select {
  border: 1px solid #FFFFFF;
  filter: drop-shadow(0px 0px 5px #FFFFFF);
  border-radius: 8px;
  background: transparent;
}

.home-banner-input:focus {
  color: #000019 !important;
}

.border-orange {
  border: 2px solid #F26B40;
}

.border-blue {
  border: 2px solid #5858C1;
}

.horizon-line {
  display: flex;
  flex-basis: 100%;
  align-items: center;
}

.horizon-line:before {
  content: '';
  flex-grow: 1;
  margin: 0 16px;
  background: #ffffff;
  height: 1px;
  font-size: 0;
  line-height: 0;
}

.horizon-line:after {
  content: '';
  flex-grow: 1;
  margin: 0 16px;
  background: #ffffff;
  height: 1px;
  font-size: 0;
  line-height: 0;
}

.toast-bg {
  background: #3361C3;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

/**********/
/* TABLET */
/**********/
@media (min-width: 768px) {
  .image-banner-title-1 {
    font-size: 3em;
  }

  .home-banner-title-1 {
    font-size: 3em;
  }

  .home-banner-title-2 {
    font-size: 0.9em;
  }

  .home-banner-title-3 {
    font-size: 1.5em;
  }
}

/*****************/
/* SMALL DESKTOP */
/*****************/
@media (min-width: 992px) {
  .image-banner-title-1 {
    font-size: 4.2em;
  }

  .home-banner-title-1 {
    font-size: 4.2em;
  }

  .home-banner-title-2 {
    font-size: 1.35em;
  }

  .home-banner-title-3 {
    font-size: 1.2em;
  }

  .navbar-mobile {
    background: rgba(0, 0, 0, 0);
    position: relative;
    width: 100%;
  }

  .btn-size-xl {
    border-radius: 40px;
    font-size: 1.7em;
    width: 300px;
    height: 75px;
  }

  .btn-size-lg {
    border-radius: 8px;
    font-size: 1.5rem;
    width: 100%;
    height: 2.9rem;
  }

  .btn-size-md {
    border-radius: 8px;
    font-size: 1.2rem;
    height: 2.8rem;
  }

  .buttons-solar-pups {
    padding-top: 8rem;
  }

  .buttons-solar-pups img {
    width: 15rem;
  }

  .bg-cover-solar-pups {
    background-repeat: no-repeat;
    background-size: 170%;
  }

  .bg-cover-headphones {
    height: 65rem;
    background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-headphones.jpg');
  }

  .bg-modal {
    background-image: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-space.png');
    background-repeat: no-repeat;
    background-size: 155%;
    background-position: center center;
    border-radius: 16px;
  }

  .bg-roadmap {
    background-image: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-startfield.webp');
    background-size: 120%;
    background-repeat: no-repeat;
    height: 80px;
    background-position: center center;
  }

  .bg-nft {
    background-image: url('https://cdn.solarpups.com/web/assets/img/solar-pups/wallpaper-space.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 50%);
  }

  .btn-solar-pups-opensea {
    width: 15rem;
  }

  .ribbon-home {
    position: absolute;
    top: -2.9rem;
    left: 0;
  }
  .ribbon-marketplace {
    width: 8rem;
    position: absolute;
    top: -1.75rem;
    left: 0;
  }
  .ribbon-marketplace-hidden {
    display: none;
  }
  .ribbon-modal {
    width: 5rem;
    position: absolute;
    top: 12.2rem;
    left: 3rem;
  }
  .ribbon-modal-card {
    width: 5rem;
    position: absolute;
    top: -1.1rem;
    left: 0;
  }
  .puppy-reveal {
    text-align: center;
    color: #FFFFFF;
    font-size: 15.6565px;
  }
}

/*****************/
/* LARGE DESKTOP */
/*****************/
@media (min-width: 1200px) {
  .image-banner-title-1 {
    font-size: 4.2em;
  }

  .home-banner-title-1 {
    font-size: 6em;
  }

  .home-banner-title-2 {
    font-size: 1.9em;
  }

  .home-banner-title-3 {
    font-size: 1.5em;
  }
}

.prompt-btn {
  background-color: #252533;
  height: 60px;
  font-weight: 600;
}
