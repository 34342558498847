body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.coming-soon {
  position: absolute;
  top: 3%;
  left: 13%;
  font-size: 0.7rem;
}

.color-aqua {
  color: #12afbd;
}

.color-fancy-gray {
  color: #75818f;
}

.color-link {
  color: #3261c3 !important;
}

.color-blue {
  color: #4d44d7;
}

.color-green {
  color: #008947;
}

.color-gradient-aqua {
  background: linear-gradient(90deg, rgba(89,81,226,1) 0%, rgba(16,185,186,1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-blue-aqua {
  border: 1px solid white;
  background: rgb(89,81,226);
  background: linear-gradient(90deg, rgba(89,81,226,1) 0%, rgba(16,185,186,1) 100%);
}

.gradient-blue {
  background: rgb(49,41,173);
  background: linear-gradient(180deg, rgba(49,41,173,1) 0%, rgba(38,37,66,1) 100%);
}

.bg-color-baby-blue {
  background-color: #f4f8fe;
}

.bg-color-gray {
  background-color: #f2f5fa;
}

.bg-color-aqua {
  background-color: #10afbd;
}

.btn-light:hover {
  background-color: #10afbd;
}

.btn-light:focus {
  background-color: #10afbd;
}

.bg-color-blue-gray {
  background-color: #252533;
}

.border-blue {
  border-bottom: 1px solid #4d44d7;
}

.border-bottom-gray {
  border-bottom: 1px solid #f2f5fa;
}

.border-heavy-left-green {
  border-left: 10px solid #008947;
}

.border-heavy-left-blue {
  border-left: 10px solid #4d44d7;
}

.pt-10 {
  padding-top:2rem;
}

.rounded-5 {
  border-radius: 0.5rem;
}

.remove-toggle-arrow::after {
  content: none;
}

.video-rounded-wrapper {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  overflow: hidden;
  z-index: 1;
}

.iphone-frame {
  width: 328px;
  height: 650px;
  background: #010101;
  border: 1px solid #1b1721;
  border-radius: 68px;
  box-shadow: inset 0 0 4px 2px #c0b7cd, inset 0 0 0 6px #342c3f;
  padding: 19px;
}

.ipad-frame {
  width: 338px;
  height: 260px;
  background: #0d0d0d;
  border-radius: 36px;
  box-shadow: inset 0 0 0 1px #babdbf, inset 0 0 1px 3px #e2e3e4;
  padding: 27px;
}

.form-check-input:checked {
  background-color: #5A50E2;
  border-color: #5A50E2;
}

/*********************/
/* MOBILE & DEFAULTS */
/*********************/


/**********/
/* TABLET */
/**********/
@media (min-width: 768px) {
  .iphone-frame {
    width: 428px;
    height: 868px;
  }

  .ipad-frame {
    width: 700px;
    height: 520px;
  }

  .coming-soon {
    position: absolute;
    top: 3%;
    left: 18%;
    font-size: 1.1rem;
  }
}

/*****************/
/* SMALL DESKTOP */
/*****************/
@media (min-width: 992px) {
  .pt-10 {
    padding-top:10rem;
  }
  .coming-soon {
    position: absolute;
    top: 3%;
    left: 13%;
    font-size: 0.75rem;
  }

  .ipad-frame {
    width: 430px;
    height: 330px;
  }
}

/******************/
/* MEDIUM DESKTOP */
/******************/
@media (min-width: 1200px) {
  .coming-soon {
    position: absolute;
    top: 3%;
    left: 13%;
    font-size: 0.9rem;
  }

  .ipad-frame {
    width: 760px;
    height: 560px;
  }
}

/*****************/
/* LARGE DESKTOP */
/*****************/
@media (min-width: 1400px) {
  .coming-soon {
    position: absolute;
    top: 3%;
    left: 14%;
    font-size: 1.0rem;
  }
}

/******************************************/
/* EXTRA LARGE DESKTOP / HIGH RES MONITOR */
/******************************************/
@media (min-width: 1700px) {
}

/******************************************/
/* EXTRA LARGE DESKTOP / HIGH RES MONITOR */
/******************************************/
@media (min-width: 2000px) {
}
