/*********************/
/* MOBILE & DEFAULTS */
/*********************/
.bg-cover-astronauts {
    height: 37rem;
    background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/banner-astronauts-mobile-v2.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-cover-moon {
    height: 48rem;
    background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/adopt-mobile.webp');
}

.bg-cover-pet-dog {
    height: 30rem;
    background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/pet-dog-mobile-v2.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-cover-presale {
    height: 40rem;
    padding-top: 20rem;
    background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/packs-mobile-v3.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-trailer {
    background-color: rgb(32,29,66);
}

.img-your-bestfriend {
    width: 20rem;
}

.img-bluedog {
    max-height: 15rem;
}

.img-triplets {
    width: 24rem;
}

.nft-card-home-container {
    overflow: hidden;
    height: 22rem;
    position: relative;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
}

.text-pamper {
    font-size: 1.4rem;
    padding-top: 3rem;
}

.video-container {
    position:relative;
    height: 38rem;
}

h1 {
    font-size: 1.8rem;
}

h2 {
    font-size: 0.8rem;
}

/**********/
/* TABLET */
/**********/
@media (min-width: 768px) {
    .bg-cover-astronauts {
        height: 22rem;
        background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/banner-astronauts.jpg');
    }

    .bg-cover-moon {
        height: 25rem;
        background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/banner-adopt.jpg');
        background-position: center center;
    }

    .bg-cover-pet-dog {
        background-image: none;
        background-color:  rgb(106,191,47);
    }

    .bg-cover-presale {
        height: 35rem;
        background: url('https://cdn.solarpups.com/web/assets/img/solar-pups/packs-presale.webp');
        padding-top: 20rem;
        background-size: cover;
    }

    .bg-trailer {
        background-color: rgb(98, 66, 152);
    }

    .img-bluedog {
        max-height: 20rem;
    }

    .img-triplets {
        width: 32rem;
    }

    .nft-card-home-container {
        height: 45rem;
    }

    .text-pamper {
        font-size: 1.8rem;
        padding-top: 14rem;
    }

    .video-container {
        height: 21rem;
    }

    h1 {
        font-size: 2.2rem;
    }

    h2 {
        font-size: 1.1rem;
    }
}

/*****************/
/* SMALL DESKTOP */
/*****************/
@media (min-width: 992px) {
    .bg-cover-presale {
        height: 40rem;
        padding-top: 26rem;
    }

    .img-bluedog {
        max-height: 16rem;
    }

    .img-your-bestfriend {
        width: 25rem;
    }

    .img-triplets {
        width: 40rem;
    }

    .nft-card-home-container {
        height: 26rem;
    }

    .text-pamper {
        font-size: 2.2rem;
        padding-top: 20rem;
    }

    .video-container {
        height: 31rem;
    }

    h1 {
        font-size: 2.3rem;
    }

    h2 {
        font-size: 1.3rem;
    }
}

/******************/
/* MEDIUM DESKTOP */
/******************/
@media (min-width: 1200px) {
    .bg-cover-presale {
        height: 49rem;
        padding-top: 33rem;
    }

    .img-bluedog {
        max-height: 18rem;
    }

    .img-your-bestfriend {
        width: 28rem;
    }

    .nft-card-home-container {
        height: 30rem;
    }

    .text-pamper {
        font-size: 2.6rem;
        padding-top: 23rem;
    }

    .video-container {
        height: 36rem;
    }


    h1 {
        font-size: 2.7rem;
    }

    h2 {
        font-size: 1.7rem;
    }
}

/*****************/
/* LARGE DESKTOP */
/*****************/
@media (min-width: 1400px) {
    .bg-cover-presale {
        height: 49rem;
        padding-top: 33rem;
    }
    .img-bluedog {
        max-height: 20rem;
    }
    .img-your-bestfriend {
        width: 42rem;
    }

    .nft-card-home-container {
        height: 38rem;
    }

    .text-pamper {
        font-size: 3.2rem;
        padding-top: 28rem;
    }

    .video-container {
        height: 43rem;
    }

    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2rem;
    }
}

/******************************************/
/* EXTRA LARGE DESKTOP / HIGH RES MONITOR */
/******************************************/
@media (min-width: 1700px) {
    .bg-cover-presale {
        height: 60rem;
        padding-top: 40rem;
    }

    .img-your-bestfriend {
        width: 55rem;
    }

    .nft-card-home-container {
        height: 48rem;
    }

    .text-pamper {
        font-size: 3.2rem;
        padding-top: 35rem;
    }

    .video-container {
        height: 51rem;
    }

    h1 {
        font-size: 3.7rem;
    }

    h2 {
        font-size: 2.7rem;
    }
}

/******************************************/
/* EXTRA LARGE DESKTOP / HIGH RES MONITOR */
/******************************************/
@media (min-width: 2000px) {
    .bg-cover-presale {
        height: 65rem;
        padding-top: 45rem;
    }

    .img-your-bestfriend {
        width: 55rem;
    }

    .nft-card-home-container {
        height: 55rem;
    }

    .text-pamper {
        font-size: 3.2rem;
        padding-top: 38rem;
    }

    .video-container {
        height: 54rem;
    }
}

.tooltip-inner {
    max-width: 400px;
    font-family: 'ReemKufi', sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: #7090D5;
}

.tooltip-arrow::before {
    bottom: 0 !important;
    border-bottom-color: #7090D5 !important;
}
